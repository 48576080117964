import React from "react";
import { graphql } from "gatsby";
import LayoutComponent from "../components/global/layout";
import SEOComponent from "../components/global/seo";
import BlogIndexListingComponent from "../components/blog/post-index-listing";

const BlogListingLayout = ({ data, pageContext }) => {
  const { allMarkdownRemark } = data;
  
  let metaDescription = "I'm Surinder Bhomra and this is my blog. I write about anything that is of interest to me, mostly about programming and life in general.";

  if (pageContext.currentPage > 1) {
    metaDescription = `Page ${pageContext.currentPage} of blog posts written by Surinder Bhomra.`
  }

  return (
    <LayoutComponent>
      <SEOComponent 
        title={`Blog${(pageContext.currentPage > 1 ? ` - Page ${pageContext.currentPage}` : "")}`}
        metaDescription={metaDescription}
        shareImageIsLarge={true} />
      <div className="divide-y">
        <header className="pt-6 pb-8 space-y-2 md:space-y-5">
          <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
            Blog
          </h1>
          <p>Blogging on programming and life in general.</p>
          {/*<div className="relative max-w-lg">
            <input
              aria-label="Search articles"
              type="text"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search articles"
              className="block w-full px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md dark:border-gray-900 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-800 dark:text-gray-100"
            />
            <svg
              className="absolute w-5 h-5 text-gray-400 right-3 top-3 dark:text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>*/}
        </header>
        <BlogIndexListingComponent data={allMarkdownRemark} path="/Blog" pageContext={pageContext} />
      </div>
    </LayoutComponent>
  )
}

export default BlogListingLayout

export const query = graphql`
  query blogPostsList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {draft: {eq: false}}, fields: {type: {eq: "post"}}}, 
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            summary
            date
            categories {
              name
              slug
            }
            tags
            teaserImage
          }
          timeToRead
        }
      }
    }
  }
`